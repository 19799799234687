.TextoLanzamiento {
  margin-top: 1em;
  color: #fff;
  font-size: 2em;
}

.FechaLanzamiento {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
  margin-bottom: 1em;
}

/* 28em x 16px = 768 px */
@media only screen and (max-width: 48em) {
  .TextoLanzamiento {
    margin-top: 1em;
  }
}

/* 22.5em x 16px = 360 px */
@media only screen and (max-width: 22.5em) {
  .TextoLanzamiento {
    margin-top: 1em;
  }
}
