.Wrapper {
  /* height: 7em; */
}
.PreOrdena {
  display: inline-block;
  background-color: rgba(23, 26, 32, 0.85);
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 100px;
  width: 16em;
  /* position: absolute; */
  margin: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  animation-name: AnimationLeft;
  animation-duration: 0.8s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  letter-spacing: 0.1em;
  text-decoration: none;
  transform: translateX(2em);
}

.PropuestaValor {
  display: inline-block;
  background-color: hsla(0, 0%, 100%, 0.5);
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 100px;
  width: 16em;
  /* position: relative; */
  margin: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  animation-name: AnimationRight;
  animation-duration: 0.8s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  letter-spacing: 0.1em;
  text-decoration: none;
  transform: translateX(2em);
  cursor: pointer;
}

@keyframes AnimationLeft {
  from {
    opacity: 0;
    transform: translateX(-2em);
  }
  to {
    opacity: 1;
    transform: translateX(0em);
  }
}

@keyframes AnimationRight {
  from {
    opacity: 0;
    transform: translateX(2em);
  }
  to {
    opacity: 1;
    transform: translateX(0em);
  }
}
