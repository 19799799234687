.Paquetes {
  margin-top: 2em;
}

/* 28em x 16px = 768 px */
@media only screen and (max-width: 48em) {
  .Paquetes {
    margin-top: 0em;
  }
}

/* 22.5em x 16px = 360 px */
@media only screen and (max-width: 22.5em) {
  .Paquetes {
    margin-top: 0em;
  }
}
