.Paquete {
  display: inline-block;
  /*
  border-color: #fff;
  border-width: 0.15em;
  border-style: solid;
  */
  overflow: hidden;
  position: relative;
  width: 13em;
  height: 13em;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.65);
  padding-top: 2.5em;
  margin-top: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

.Velocidad {
  font-size: 2em;
  font-weight: bold;
  margin: 0.7em;
}

.Precio {
  font-size: 1.8em;
  margin: 0.8em;
  line-height: 0.8em;
}

.PrecioMonto {
  font-weight: bold;
}

.TextoPromocion {
  display: inline-block;
  font-weight: bold;
  font-size: 0.5em;
  border-bottom: #393c41 1px solid;
  cursor: pointer;
}

.Instalacion {
  font-size: 0.5em;
  font-weight: bold;
}
