.Circle {
  width: 13em;
  height: 13em;
  border-radius: 50%;
  box-shadow: 6.5em -6.5em 0 0.2em #000, -6.5em -6.5em 0 0.2em #000,
    -6.5em 6.5em 0 0.2em #000, 6.5em 6.5em 0 0.2em #000, 0 0 0 0.2em #fff;
  animation: border 0.5s ease 2s forwards;
  position: absolute;
  top: -1em;
  z-index: -1;
  transform: scale(0.98);
}

@keyframes border {
  0% {
    box-shadow: 6.5em -6.5em 0 0.2em #000, -6.5em -6.5em 0 0.2em #000,
      -6.5em 6.5em 0 0.2em #000, 6.5em 6.5em 0 0.2em #000, 0 0 0 0.2em #fff;
  }
  25% {
    box-shadow: 0 -13.5em 0 0.2em #000, -6.5em -6.5em 0 0.2em #000,
      -6.5em 6.5em 0 0.2em #000, 6.5em 6.5em 0 0.2em #000, 0 0 0 0.2em #fff;
  }
  50% {
    box-shadow: 0 -13.5em 0 0.2em #000, -13.5em 0 0 0.2em #000,
      -6.5em 6.5em 0 0.2em #000, 6.5em 6.5em 0 0.2em #000, 0 0 0 0.2em #fff;
  }
  75% {
    box-shadow: 0 -13.5em 0 0.2em #000, -13.5em 0 0 0.2em #000,
      0px 13.5em 0 0.2em #000, 6.5em 6.5em 0 0.2em #000, 0 0 0 0.2em #fff;
  }
  100% {
    box-shadow: 0 -13.5em 0 0.2em #000, -13.5em 0 0 0.2em #000,
      0 13.5em 0 0.2em #000, 13.5em 0em 0 0.2em #000, 0 0 0 0.2em #fff;
  }
}
