.HeaderLeft {
  color: #fff;
  text-align: left;
  padding: 0.7em;
  position: relative;
  font-size: 1.2em;
  z-index: 2;
  max-width: 10em;
  /* opacity: 0;
  animation-name: HeaderShowAnimation;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards; */
}

.TowerIcon {
  width: 1em;
  filter: invert(1);
  position: relative;
  margin-left: 0.2em;
  top: 0.1em;
}

.HeaderRight {
  color: #fff;
  text-align: left;
  margin: 1em;
  position: absolute;
  z-index: 2;
  top: 0em;
  right: 0em;
}

.HeaderIcon {
  width: 2em;
  position: relative;
  top: -0.5em;
}

.ContactoIcon {
  display: inline;
  width: 2em;
  transform: translateY(0.6em);
}

/* @keyframes HeaderShowAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.Menu {
  position: absolute;
  left: 50%;
  top: 0%;
  margin-left: -50%;
  width: 100%;
  background-color: rgba(23, 26, 32, 0.8);
  z-index: 1;
}

@media only screen and (min-width: 48em) {
  .Menu {
    margin-top: -4em;
    animation-name: MenuShowAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  @keyframes MenuShowAnimation {
    to {
      margin-top: 0em;
    }
  }
}

@media only screen and (max-width: 48em) {
  .Menu {
    animation-name: MenuShowAnimationSmall;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  @keyframes MenuShowAnimationSmall {
    to {
      opacity: 0.95;
    }
  }
}

.MenuItem {
  color: #fff;
  display: inline-block;
  padding-left: 0.8em;
  padding-right: 0.8em;
  letter-spacing: 0.1em;
  cursor: pointer;
}

/* 28em x 16px = 768 px */
@media only screen and (max-width: 48em) {
  .HeaderLeft {
    text-align: center;
    max-width: 100%;
    background-color: rgba(23, 26, 32, 0.8);
  }
  .Menu {
    position: relative;
    padding: 1em;
  }
  .MenuItem {
    display: block;
  }
}

/* 22.5em x 16px = 360 px */
@media only screen and (max-width: 22.5em) {
  .HeaderLeft {
    text-align: center;
    max-width: 100%;
    background-color: rgba(23, 26, 32, 0.8);
  }
  .Menu {
    position: relative;
    padding: 1em;
  }
  .MenuItem {
    display: block;
  }
}

.ListaPreguntas > li {
  margin-bottom: 1em;
}

.Pregunta {
  font-weight: bold;
}

.Respuesta {
  display: block;
}
